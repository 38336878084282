/* eslint-disable */
import { obterDataHora } from '../../util/date';

type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

export const RelatorioXLSUser = (state: any, setExcelGenerationUser: any) => {
  const titleAlignment = {
    horizontal: 'center',
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const borderTitle = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  let excel: ExcelGeneration2[] = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Relatório Checklist',
          widthPx: 1060,
          style: {
            alignment: titleAlignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 1,
      ySteps: 0,
      columns: [],
      data: [],
    },
  ];

  const mergeNomesDispositivos = (checklistComDefeito: any): string => {
    let nomesDispositivo: string = '';
    checklistComDefeito.forEach((dispositivo: any) => {
      if (dispositivo.situacaoDispositivo === '1') {
        return;
      } else {
        nomesDispositivo +=
          nomesDispositivo === ''
            ? dispositivo.nomeDispositivo
            : ', ' + dispositivo.nomeDispositivo;
      }
    });
    return nomesDispositivo;
  };

  const statusDispositivo = (veiculos: any): string => {
    let status: string = '';
    veiculos.forEach((dispositivo: any) => {
      status = dispositivo.situacaoDispositivo === '3' ? 'Defeito' : 'Ok';
    });
    return status;
  };

  const loadDados = () => {
    state.listagem.map((veiculo: any, i: any) => {
      if (veiculo.listaDispositivo.length > 0) {
        var tableData = {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: 'Data Realização',
              width: { wch: 20 },
              style: { border: borderTitle, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Validade',
              width: { wch: 20 },
              style: { border: borderTitle, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Responsável',
              width: { wch: 30 },
              style: { border: borderTitle, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Operador',
              width: { wch: 30 },
              style: { border: borderTitle, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Dispositivo(s) pendente(s) de manutenção',
              width: { wch: 100 },
              style: { border: borderTitle, font: { sz: '12' }, alignment: alignment },
            },
          ],
          data: [
            [
              {
                value: veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao, true) : '',
                style: { border: borders2, font: { sz: '12' }, alignment: alignment },
              },
              {
                value: `${
                  veiculo?.listaDispositivo?.filter((each: any) =>
                    each?.situacaoDispositivo?.includes('3')
                  ).length > 0
                    ? `Checklist Não OK`
                    : veiculo?.checklistValidade?.includes('EMBARQUE')
                    ? veiculo?.checklistValidade.split(': ')[1]
                    : veiculo?.dataValidade
                    ? obterDataHora(veiculo?.dataValidade, true)
                    : ''
                }`,
                style: { border: borders2, font: { sz: '12' }, alignment: alignment },
              },
              {
                value: `${veiculo.responsavel !== null ? veiculo.responsavel : ''}`,
                style: { border: borders2, font: { sz: '12' }, alignment: alignment },
              },
              {
                value: `${veiculo.usuarioLancamento !== null ? veiculo.usuarioLancamento : ''}`,
                style: { border: borders2, font: { sz: '12' }, alignment: alignment },
              },
              {
                value: `${mergeNomesDispositivos(veiculo.listaDispositivo)}`,
                style: { border: borders2, font: { sz: '12' }, alignment: alignment },
              },
            ],
          ],
        };

        var linhaEntreVeiculos = {
          xSteps: 2,
          ySteps: 0,
          columns: [],
          data: [],
        };

        excel.push(tableData);
        excel.push(linhaEntreVeiculos);
      }
    });
  };

  loadDados();
  setExcelGenerationUser(excel);
};

export default RelatorioXLSUser;
