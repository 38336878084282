/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Spinner, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import axios from '../../../config/axiosMaquina';

import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import LscModal from './lscModal';
import { getCookieSessionData } from '../../../services/cookieService';
import ModalVelocidadeVeiculo from './modal';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { useToast } from '../../../hooks/Toast';
import Pagination from '../../Home/Pagination/Pagination';
import { Tela } from '../../../components/Tela';
import ReactExport from 'react-data-export';

type ViolacaoVelocidade = {
  enviaComandoSirene: boolean;
  enviaEmail: boolean;
  enviaMensagemVeiculo: boolean;
  enviaSms: boolean;
  enviaWhatsapp: boolean;
  idCliente: number;
  idViolacao: number;
  listaEmails: string;
  listaNumerosTelefone: string;
  mensagemEmailSmsWapp: string;
  mensagemTeclado: string;
  moduloTipo: string;
  velocidadeMaximaFrota: number;
  velocidadeMaximaIndividual: number;
};

const VelocidadePorVeiculo: React.FC = () => {
  const [lastPage, setLastPage] = useState(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);

  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canInsert } = useRolePermission();

  const { clienteId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();
  const history = useHistory();
  const [veiculos, setVeiculos] = useState<VeiculoTypeGrid[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<VeiculoTypeGrid[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [velocidadeMaxima, setVelocidadeMaxima] = useState('');
  const initialState = {
    enviaComandoSirene: false,
    enviaEmail: false,
    enviaMensagemVeiculo: false,
    enviaSms: false,
    enviaWhatsapp: false,
    idCliente: clienteId,
    idViolacao: 0,
    listaEmails: '',
    listaNumerosTelefone: '',
    mensagemEmailSmsWapp: '',
    mensagemTeclado: '',
    moduloTipo: '',
    velocidadeMaximaFrota: 0,
    velocidadeMaximaIndividual: 0,
  };
  const [violacao, setViolacao] = useState<ViolacaoVelocidade>(initialState);
  const [erroVelocidadeMaxima, setErroVelocidadeMaxima] = useState(false);

  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [loadingLink, setLoadingLink] = useState(false);

  const [dialogVincularVeiculo, setDialogVincularVeiculo] = useState<boolean>(false);
  const [dialogVincularVeiculoPlaca, setDialogVincularVeiculoPlaca] = useState<string>('');
  const [dialogVincularVeiculoID, setDialogVincularVeiculoID] = useState<number>(0);
  const [excelGeneration, setExcelGeneration] = useState([]);

  const [showResponseMessage, setShowResponseMessage] = useState('');

  const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

  const generateExcelData = (data: any[]) => {
    return data.map((item) => ({
      placa: item.placa,
      veiculo_marca_nome: item.veiculo_marca_nome,
      veiculo_tipo_nome: item.veiculo_tipo_nome,
      velocidade_individual_veiculo:
        item.velocidade_individual_veiculo === null
          ? 'Velocidade Padrão'
          : `${item.velocidade_individual_veiculo} km/h`,
    }));
  };

  const ExportExcel = ({ veiculos }: any) => {
    const transformedData = generateExcelData(veiculos);

    return (
      <ExcelFile element={<Button>Exportar Excel</Button>} filename="Veiculos">
        <ExcelSheet data={transformedData} name="Veiculos">
          <ExcelColumn label="Placa" value="placa" />
          <ExcelColumn label="Marca do Veículo" value="veiculo_marca_nome" />
          <ExcelColumn label="Tipo do Veículo" value="veiculo_tipo_nome" />
          <ExcelColumn label="Velocidade" value="velocidade_individual_veiculo" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  useEffect(() => setSearchTerm(null), []);

  const handleLink = (id: number, placa: string) => {
    setLoadingLink(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/update-vehicle-speed-monitoring/`, [
        {
          cliente: clienteId,
          id,
          monitoring: false,
        },
      ])
      .then((res) => {
        setLoadingLink(false);

        setDialogVincularVeiculo(false);
        setDialogVincularVeiculoPlaca('');
        setDialogVincularVeiculoID(0);
        setRefreshList(true);

        setShowResponseMessage(res.data.detail);
      })
      .catch((e) => {
        console.log('');
      });
  };

  useEffect(() => {
    if (!refreshList) return;
    loadDatas({
      page: currentPage,
      clientId: clienteId,
      page_size: itensPerPage,
    });
  }, [refreshList]);

  useEffect(() => {
    if (!refresh) return;

    loadDatas({
      page: refresh,
      clientId: clienteId,
      page_size: itensPerPage,
    });
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;

    loadDatas({
      page: currentPage,
      clientId: clienteId,
      page_size: itensPerPage,
    });

    setRefreshitensPerPage(false);
  }, [refreshitensPerPage]);

  const getViolacao = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/violacao-velocidade/idCliente?idCliente=${clienteId}`);
      if (!response.data.idViolacao) {
        setViolacao(initialState);
      } else {
        setViolacao(response.data);
        setVelocidadeMaxima(response.data.velocidadeMaximaFrota);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [clienteId]);

  const loadVeiculos = async ({
    page_size,
    placa,
    page,
    clientId,
  }: {
    page_size: string;
    placa?: string;
    page?: string;
    clientId: string;
  }) => {
    setLoading(true);
    setVeiculos([]);
    if (!clientId) {
      return;
    }
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/vehicle-control-speed/`, {
          params: {
            page,
            cliente: clientId,
            speed: true,
            page_size,
            ...(placa && { placa: placa.trim() }),
          },
        })
        .then((res) => {
          setTotalItems(res.data.count);

          setLastPage(Math.ceil(res.data.count / Number(page_size)));
          setVeiculos(res.data.results);
          setLoading(false);
          setRefreshList(false);
        })
        .catch(() => {
          setRefreshList(false);
          setVeiculos([]);
          setLoading(false);
        });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE] }],
      'editar'
    );
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE] }],
      'inserir'
    );
  }, [TRANSPORTADOR, rolePermission]);

  const loadDatas = ({
    page_size,
    clientId,
    page,
    placa,
  }: {
    page_size: string;
    clientId: any;
    page: any;
    placa?: string;
  }) => {
    getViolacao();
    loadVeiculos({ page_size, clientId, page, placa });
  };

  useEffect(() => {
    if (clienteId) {
      loadDatas({ page_size: itensPerPage, clientId: clienteId, page: currentPage });
    }
  }, [clienteId]);

  useEffect(() => {
    if (searchTerm === null) return;

    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        if (searchTerm?.trim() === '') {
          loadDatas({
            page: currentPage,
            clientId: clienteId,
            page_size: itensPerPage,
          });
          return;
        }
        loadDatas({
          page: currentPage,
          clientId: clienteId,
          page_size: itensPerPage,
          placa: searchTerm,
        });
      };

      fetchData();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  const handleVelocidadeFrota = async (e: any) => {
    e.preventDefault();
    if (erroVelocidadeMaxima) {
      addToast({
        title: 'Erro!',
        description: 'Velocidade máxima não pode ser menor que 50km/h ou maior que 140km/h.',
        type: 'error',
      });
      return;
    }
    if (velocidadeMaxima === '') {
      addToast({
        title: 'Erro!',
        description: 'Velocidade máxima inválida.',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      setLoading(true);
      if (violacao.idViolacao === 0) {
        await axios.post('/violacao-velocidade/salvar', violacao);
        addToast({
          title: 'Cadastrado!',
          description: 'Cadastrado com sucesso',
          type: 'success',
        });
      } else {
        await axios.put(
          `/violacao-velocidade/atualizar?idViolacao=${violacao.idViolacao}`,
          violacao
        );
        addToast({
          title: 'Atualizado!',
          description: 'Atualizado com sucesso',
          type: 'success',
        });
      }
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro!',
        description: `${violacao.idViolacao === 0 ? 'Erro ao cadastrar' : 'Erro ao atualizar'}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
      getViolacao();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={!!showResponseMessage.length}
        onHide={() => {
          setShowResponseMessage('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>{showResponseMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowResponseMessage('');
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        header={`Tem certeza que deseja desvincular o veículo (${dialogVincularVeiculoPlaca})?`}
        footer={
          <>
            <Button
              style={{
                background: '#df5252',
                color: '#fff',
                border: 'none',
                height: '40px',
              }}
              onClick={() => {
                setDialogVincularVeiculo(false);
                setDialogVincularVeiculoPlaca('');
                setDialogVincularVeiculoID(0);
              }}
            >
              Não
            </Button>
            <Button
              style={{
                background: '#8f52df',
                color: '#fff',
                border: 'none',
                height: '40px',
              }}
              onClick={() => {
                handleLink(dialogVincularVeiculoID, dialogVincularVeiculoPlaca);
              }}
            >
              {loadingLink ? 'Desvinculando...' : 'Sim'}
            </Button>
          </>
        }
        visible={dialogVincularVeiculo}
        style={{ width: '50vw' }}
        modal
        closable={false}
        onHide={() => {
          setDialogVincularVeiculo(false);
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Controle de Velocidade</h2>
          <h6 className="subtitulo">{'Controle Logístico > Controle de Velocidade'}</h6>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              marginBottom: 24,
              marginLeft: 5,
            }}
          >
            <Form.Row>
              <Form.Label>Velocidade Máxima da Frota (km/h)</Form.Label>
              <Form.Control
                name="proprietarioId"
                placeholder="Km/h"
                value={velocidadeMaxima}
                max={140}
                maxLength={3}
                onChange={(e) => {
                  setVelocidadeMaxima(e.target.value);
                  setViolacao({
                    ...violacao,
                    velocidadeMaximaFrota: parseInt(e.target.value, 10),
                  });
                  if (parseInt(e.target.value, 10) > 140 || parseInt(e.target.value, 10) < 50) {
                    setErroVelocidadeMaxima(true);
                  } else {
                    setErroVelocidadeMaxima(false);
                  }
                }}
                required
              />
            </Form.Row>
          </div>
          <Button
            type="submit"
            onClick={(e) =>
              canEdit || canInsert ? handleVelocidadeFrota(e) : history.push('/acessonegado')
            }
            style={{
              color: '#111',
              fontSize: '1em',
              fontWeight: 500,
              marginLeft: 30,
              maxHeight: 38,
              padding: '5px 20px',
            }}
          >
            Confirmar
          </Button>

          <LscModal clienteId={clienteId} setRefreshList={setRefreshList} />

          <div className="search-create mb-4" style={{ width: '100%', marginTop: 25 }}>
            <span className="p-input-icon-left">
              <input
                type="text"
                placeholder="Pesquise pela placa"
                className="p-inputtext p-component"
                id="search-input"
                style={{ width: '240px' }}
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <i className="pi pi-search" />
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ExportExcel veiculos={veiculos} />
          </div>

        <div className="content-container" style={{ width: '100%' }}>

          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <>
                  {veiculos.length > 0 && (
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          <th>Placa</th>
                          <th>Marca</th>
                          <th>Modelo</th>
                          <th>Tipo</th>
                          <th>Velocidade Máxima</th>
                          <th> </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {veiculos.map((veiculo: any) => {
                          return (
                            <tr key={veiculo.id}>
                              <td>{veiculo.placa === null ? '' : veiculo.placa}</td>
                              <td>{veiculo.veiculo_marca_nome}</td>
                              <td>{veiculo.modelo}</td>
                              <td>{veiculo.veiculo_tipo_nome}</td>
                              <td>
                                {!veiculo.velocidade_individual_veiculo
                                  ? 'Velocidade Padrão'
                                  : `${veiculo.velocidade_individual_veiculo} km/h`}
                              </td>

                              <td>
                                <Button
                                  onClick={() => {
                                    setDialogVincularVeiculo(true);
                                    setDialogVincularVeiculoPlaca(veiculo.placa);
                                    setDialogVincularVeiculoID(veiculo.id);
                                  }}
                                  style={{
                                    background: '#df5252',
                                    color: '#fff',
                                    border: 'none',
                                    height: '40px',
                                  }}
                                >
                                  DESVINCULAR
                                </Button>
                              </td>

                              <td>
                                <div className="icons">
                                  <ModalVelocidadeVeiculo
                                    veiculo={veiculo}
                                    clienteId={clienteId}
                                    canEdit={canEdit}
                                    setRefreshList={setRefreshList}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>

          {veiculos.length > 0 && (
            <Pagination
              lastPage={lastPage}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalItems}
              paginate={paginate}
              currentPage={String(currentPage)}
              setRefresh={setRefresh}
              setData={setVeiculos}
              setRefreshitensPerPage={setRefreshitensPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VelocidadePorVeiculo;
