/* eslint-disable */
import { obterData, obterDataHora } from '../../util/date';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';

export const RelatorioXLSLancado = (state: any, setExcelGenerationUser: any) => {
  const total = state.listagem.length;

  let excel = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Checklist Lançados',
          widthPx: 3000,
          style: {
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: `Periodo: ${
            state.model.dataInicio ? obterData(state.model.dataInicio).toString() : '/ /'
          } e ${state.model.dataFim ? obterData(state.model.dataFim).toString() : '/ /'}`,
          width: { wpx: 2000 },
          style: { font: { sz: '12' }, alignment: { horizontal: 'center' } },
        },
        // { value: `Total: ${String(state.listagem.length)}` },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Checklist n°' },
        { title: 'Empresa' },
        { title: 'Placa' },
        { title: 'Descrição' },
        { title: 'Tipo Frota' },
        { title: 'Contrato' },
        { title: 'Data/Hora Realização' },
        { title: 'Status' },
        { title: 'Usuário' },
        { title: 'Embarcador' },
        { title: 'CD Origem' },
        { title: 'Data/Hora/Usuário Lançamento' },
      ],
      data: state.listagem.map((each: any) => [
        { value: each.idChecklist ?? '' },
        { value: each.nomeClienteTransportador ?? '' },
        { value: each.placaVeiculo ?? '' },
        { value: each.modeloCarreta || each.modeloVeiculo || each.modeloBau || '' },
        {
          value:
            getTipoFrota(each.tipoFrotaCarreta) ||
            getTipoFrota(each.tipoFrotaVeiculo) ||
            getTipoFrota(each.tipoFrotaBau) ||
            '',
        },
        { value: each.tipoFaturamento ?? '' },
        { value: obterDataHora(each.dataInclusao) ?? '' },
        {
          value:
            each.checklistValidade ?? each.checklistValidade.includes('OK')
              ? 'Checklist Válido'
              : 'Checklist Inválido',
        },
        { value: each.usuarioLancamento ?? '' },
        { value: each.nomeClienteEmbarcador ?? '' },
        { value: each.cdOrigem ?? '' },
        {
          value:
            obterDataHora(each.dataInclusao || '') +
            ' ' +
            obterDataHora(each.usuarioLancamento || ''),
        },
      ]),
    },
  ];

  setExcelGenerationUser(excel);
};

export default RelatorioXLSLancado;
