/* eslint-disable */
import { useHistory } from 'react-router';

export const itemsAdmin = () => {
  const useNavigation = (path: string) => {
    const history = useHistory();
    return () => {
      history.push(path);
    };
  };

  const itemsAdminList = [
    {
      label: 'Home',
      command: useNavigation('/home'),
    },

    {
      label: 'Cadastro',
      items: [
        {
          label: 'Clientes',
          items: [
            {
              label: 'Consulta / Cadastro',
              command: useNavigation('/clientes/listar/1'),
            },
          ],
        },

        {
          label: 'Rastreadores',
          items: [
            {
              label: 'Tipos de Comunicação',
              command: useNavigation('/listar/tipos-de-comunicacao/1'),
            },
            {
              label: 'Tecnologia',
              command: useNavigation('/listar/tecnologias-de-rastreamento/1'),
            },
            {
              label: 'Marca',
              command: useNavigation('/listar/marcas/1'),
            },
            {
              label: 'Dispositivos',
              command: useNavigation('/listar/dispositivos/1'),
            },
          ],
        },

        {
          label: 'Veículos',
          items: [
            {
              label: 'Grupo Macro de Veículos',
              command: useNavigation('/listar/grupo-macro-de-veiculos'),
            },
            {
              label: 'Grupos de Veículos',
              command: useNavigation('/listar/grupos-de-veiculos/1'),
            },
            {
              label: 'Tipos de Veículos',
              command: useNavigation('/listar/tipos-de-veiculo/1'),
            },

            {
              label: 'Cor',
              command: useNavigation('/listar/cor/1'),
            },
            {
              label: 'Marca',
              command: useNavigation('/listar/veiculo-marcas/1'),
            },
            {
              label: 'Rastreamento',
              command: useNavigation('/listar/rastreamento'),
            },
          ],
        },
        {
          label: 'Gerenciamento de Clientes',
          items: [
            {
              label: 'Ajudante Funcionário (Não Motorista)',
              command: useNavigation('/admin/filtrar/ajudantes'),
            },
            {
              label: 'Carreta',
              command: useNavigation('/admin/filtrar/carretas'),
            },
            {
              label: 'Condutor',
              command: useNavigation('/admin/filtrar/condutores'),
            },
            {
              label: 'Embarcador (Regras de Apólice)',
              command: useNavigation('/admin/listar/embarcadores/1'),
            },
            {
              label: 'Proprietário',
              command: useNavigation('/admin/filtrar/proprietarios'),
            },
            {
              label: 'Veículos',
              command: useNavigation('/admin/filtrar/veiculos'),
            },
            {
              label: 'BlackList Seguradora',
              command: useNavigation('/admin/listar/blacklist-seguradora/1'),
            },
            {
              label: 'Validação CNH',
              command: useNavigation('/admin/listar/validacao-cnh/1'),
            },
          ],
        },
        {
          label: 'Gerentes',
          command: useNavigation('/admin/listar/gerentes/1'),
        },
        {
          label: 'Motivos',
          command: useNavigation('/listar/motivos/1'),
        },
        {
          label: 'Não Conformidades',
          command: useNavigation('/listar/nao-conformidades/1'),
        },

        {
          label: 'Analistas de Perfil',
          command: useNavigation('/listar/analistas-de-perfil/1'),
        },

        {
          label: 'Corretora',
          command:
            // window.open(`${process.env.REACT_APP_SC_URL}/grid_public_corretora/`, '_scriptCase');
            useNavigation('/listar/corretoras'),
        },

        {
          label: 'Seguradora',
          command: useNavigation('/listar/seguradoras'),
          // window.open(`${process.env.REACT_APP_SC_URL}/grid_public_seguradora/`, '_scriptCase');
        },
      ],
    },

    {
      label: 'Operacional',
      items: [
        {
          label: 'Solicitações',
          items: [
            {
              label: 'Análises de Perfil',
              items: [
                {
                  label: 'Lançamento',
                  command: useNavigation('/analise-perfil/grid'),
                },
                {
                  label: 'Painel Análise Perfil',
                  command: useNavigation('/analise-perfil/painel/1'),
                },
              ],
            },
            {
              label: 'Assistência Técnica',
              command: useNavigation('/admin/listagem-sat/1'),
            },
            {
              label: 'Checklist Veículos',
              command: useNavigation('/cliente/operacional/listar-checklist/1'),
            },
          ],
        },
        {
          label: 'Painel Operacional',
          command: useNavigation('/admin/painel-operacional/pendentes/1'),
        },
        {
          label: 'Tecnologias',
          command: useNavigation('/listar/historico-tecnologias/1'),
        },
        {
          label: 'Faturamento/Frota',
          command: useNavigation('/listar/faturamento-frota/1'),
        },

        {
          label: 'Painel LSC',
          command: useNavigation('/painel-lsc/1'),
        },
      ],
    },

    {
      label: 'Avisos',
      items: [
        {
          label: 'Aviso Clientes',
          command: useNavigation('/listar/avisos-clientes'),
        },
        {
          label: 'Aviso Operacional',
          command: useNavigation('/listar/avisos-operacionais'),
        },
      ],
    },

    {
      label: 'Consultas / Relatórios',
      items: [
        {
          label: 'Análise Perfil',
          command: useNavigation('/consultar-pesquisas-lancadas/'),
        },
        {
          label: 'Pesquisas Tratadas',
          command: useNavigation('/consultar-pesquisas-tratadas/'),
        },
        {
          label: 'Checklist',
          items: [
            {
              label: 'Detalhado',
              command: useNavigation('/checklist/filtro-checklist-detalhado'),
            },
            {
              label: 'Lançados',
              command: useNavigation('/checklist/filtro-checklist'),
            },
          ],
        },
        {
          label: 'Rastreamento',
          items: [
            {
              label: 'Histórico de Posições',
              command: useNavigation('/rastreamento/historico-posicoes/1'),
            },
            {
              label: 'Requisições API',
              command: useNavigation('/rastreamento/requisicao-api'),
            },
            {
              label: 'Tempo Parado',
              command: useNavigation('/rastreamento/tempo-parado'),
            },
          ],
        },
        {
          label: 'Assistência Técnica',
          items: [
            {
              label: 'Resumo SAT',
              command: useNavigation('/consultar-sat'),
            },
          ],
        },
        {
          label: 'SM',
          items: [
            {
              label: 'SM Lançadas',
              command: useNavigation('/consultar-sm-lancadas'),
            },
            {
              label: 'Não Conformidades',
              command: useNavigation('/nao-conformidade/consulta'),
            },
          ],
        },
      ],
    },

    {
      label: 'Parâmetros',
      items: [
        {
          label: 'E-mail',
          command: useNavigation('/parametros/servicoSmpt'),
        },
        {
          label: 'Integração',
          command: useNavigation('/parametros/integracao'),
        },
        {
          label: 'Sistema',
          command: useNavigation('/parametros/sistema'),
        },
        {
          label: 'Logs',
          items: [
            {
              label: 'Log da pesquisa',
              command: useNavigation('/parametros/logs/integracao-dminer/1'),
            },
          ],
        },
        // {
        //   label: 'Automatização',
        //   command: useNavigation('/parametros/automatizacao'),
        // },
      ],
    },

    {
      label: 'Usuários',
      items: [
        {
          label: 'Usuários Gerenciadores',
          command: useNavigation('/listar-usuarios-gerenciadores/1'),
        },
        {
          label: 'Usuários Corretora',
          command: useNavigation('/listar-usuarios-corretoras/1'),
        },
        {
          label: 'Usuários Seguradora',
          command: useNavigation('/listar-usuarios-seguradoras/1'),
        },
        {
          label: 'Associar Clientes',
          command: useNavigation('/associar-clientes'),
        },
        {
          label: 'Perfis de Usuários GR',
          command: useNavigation('/listar-perfis-usuarios-gr/1'),
        },
        {
          label: 'Perfis de Usuários Clientes',
          command: useNavigation('/listar-perfis-usuarios/1'),
        },
        {
          label: 'Usuários Clientes',
          command: useNavigation('/listar-usuarios-clientes/1'),
        },
      ],
    },

    {
      label: 'Fazer Logout',
      icon: 'pi pi-fw pi-user-minus',
      command: useNavigation('/logout'),
    },
  ];

  return itemsAdminList;
};
