/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { ListagemCliente, ListagemEmbarcador } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import '../ConsultarPesquisasLancadas/styles.css';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { toZoned } from '../../util/date';
import { MultiSelect } from 'primereact/multiselect';
import { TIPO_FROTA_VEICULO } from '../../constants/checklist-detalhado/TipoFrotaVeiculos';
import { TIPO_FATURAMENTO_VEICULO } from '../../constants/checklist-detalhado/TipoFaturamentoVeiculos';
import { EmpresaComboBox } from '../ConsultarSMLancadas/ConsultarSMLancadas';
import instanceMaquina from '../../config/axiosMaquina';

type ListagemConsulta = {
  checklistValidade: string;
  dataInclusao: any;
  dataValidade: any;
  idChecklist: Number;
  idClienteEmbarcador: Number;
  idClienteTransportador: Number;
  listaDispositivo: [
    {
      idChecklist: Number;
      idDispositivo: Number;
      nomeDispositivo: string;
      situacaoDispositivo: string;
      tipoDispositivo: string;
    }
  ];
  marcaBau: string;
  marcaCarreta: string;
  marcaVeiculo: string;
  modeloBau: string;
  modeloCarreta: string;
  modeloVeiculo: string;
  numeroBau: string;
  placaCarreta: string;
  placaVeiculo: string;
  responsavel: string;
  telefoneResponsavel: string;
  tipoFrotaCarreta: string;
  tipoFrotaVeiculo: string;
  usuarioLancamento: string;
};

export type FiltroResumoChecklist = {
  dataFim: string | null;
  dataInicio: string | null;
  idClienteEmbarcador: number;
  nomeClienteTransportador: string | null;
  idClienteTransportador: number;
  idUsuario: number;
  numeroBau: string | null;
  placaCarreta: string | null;
  placaVeiculo: string | null;
  statusNaoVencida: string | null;
  statusVencida: string | null;
  tipoFrota: string[];
  tipoFaturamento: string[];
  filtrarCarreta: boolean;
};

const FiltroChecklist = () => {
  const [listaTransportador, setListaTransportador] = useState<ListagemCliente[]>([]);
  const [listaEmbarcador, setListaEmbarcador] = useState<ListagemEmbarcador[]>([]);

  const [embarcador, setEmbarcador] = useState('');
  const [transportador, setTransportador] = useState('');
  const listaTipos = [
    { value: '1', nome: 'Próprio' },
    { value: '2', nome: 'Agregado' },
    { value: '3', nome: 'Terceiro' },
  ];
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [tipo, setTipo] = useState('');
  const [model, setModel] = useState<FiltroResumoChecklist>({} as FiltroResumoChecklist);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const { addToast } = useToast();
  const history = useHistory();
  const [isPlacaRegisteredVeiculo, setIsPlacaRegisteredVeiculo] = useState(true);
  const [isPlacaRegisteredCarreta, setIsPlacaRegisteredCarreta] = useState(true);
  const [isNumeroRegisteredBau, setIsNumeroRegisteredBau] = useState(true);
  const [dataFimError, setDataFimError] = useState('');
  const [selectedItemsTypeFleetVehicle, setSelectedItemsTypeFleetVehicle] = useState<string[]>([]);
  const [selectAllTypeFleetVehicle, setSelectAllTypeFleetVehicle] = useState(false);
  const [selectAllTypeBillingVehicle, setSelectAllTypeBillingVehicle] = useState(false);
  const [selectedItemsTypeBillingVehicle, setSelectedItemsTypeBillingVehicle] = useState<string[]>(
    []
  );
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [empresaArr, setEmpresaArr] = useState<EmpresaComboBox[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const { cnpj, usuarioId, role } = getCookieSessionData().usuarioVO;

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaTransportador(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    instanceMaquina
      .get('/cliente/grid-cadastro', {
        params: {
          ...(role === 'corretora' && { idUsuarioCorretora: usuarioId }),
          ...(role === 'seguradora' && { idUsuarioSeguradora: usuarioId }),
        },
      })
      .then((response) => {
        setEmpresaArr(response.data);
      });
  }, []);

  useEffect(() => {
    instanceMaquina.get('/usuario/gerenciador').then((response) => {
      setUsuarios(response.data);
    });
  }, []);

  useEffect(() => {
    const loadDadosEmbarcadorIdRazaoSocial = async () => {
      try {
        const response = await axios.get(`/embarcador/listar-todos`);
        const listaAtivos = response.data.filter((fil: any) => fil.statusAtivo === true);
        setListaEmbarcador(listaAtivos);
      } catch (err: any) {
        console.error(err);
      }
    };

    loadDadosEmbarcadorIdRazaoSocial();
  }, []);

  const buscar = async (event: any) => {
    event.preventDefault();

    // if (!model.idClienteTransportador && isAllowedByRole(['admin'])) {
    //   addToast({
    //     title: 'Erro',
    //     description: 'Preencha os dados obrigatórios!',
    //     type: 'error',
    //   });
    //   setIsFormInvalid(true);
    //   event.stopPropagation();
    //   return;
    // }

    if (model.placaVeiculo) {
      if (isPlacaRegisteredVeiculo === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de veículo não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.placaCarreta) {
      if (isPlacaRegisteredCarreta === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de carreta não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.numeroBau) {
      if (isNumeroRegisteredBau === false) {
        addToast({
          title: 'Erro',
          description: 'Número de baú não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    handleRequest();
  };

  const handleRequest = async () => {
    const formatTypeBilling =
      Array.isArray(model.tipoFaturamento) && model.tipoFaturamento.length
        ? JSON.stringify(model.tipoFaturamento)
        : undefined;
    const formatTypeFleet =
      Array.isArray(model.tipoFrota) && model.tipoFrota.length
        ? JSON.stringify(model.tipoFrota)
        : undefined;

    const filteredModel: Partial<FiltroResumoChecklist> = { ...model };

    // Remove os campos caso estejam vazios ou inválidos
    if (model.idClienteTransportador === 0) delete filteredModel.idClienteTransportador;
    if (model.idUsuario === 0) delete filteredModel.idUsuario;
    if (!formatTypeBilling) delete filteredModel.tipoFaturamento;
    if (!formatTypeFleet) delete filteredModel.tipoFrota;

    if (isAllowedByRole(['user'])) {
      setLoading(true);

      try {
        const response = await api.get(`/checklist-veiculo/consulta-gerenciar-checklist`, {
          params: {
            ...filteredModel,
            tipoFaturamento: formatTypeBilling,
            tipoFrota: formatTypeFleet,
            idClienteTransportador: clienteId !== 0 ? clienteId : undefined,
          },
        });

        setLoading(false);
        if (response.status === 200) {
          setDataResults(response.data);
          if (response.data.length === 0) {
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            return;
          } else {
            history.push({
              pathname: '/checklist/resumo-checklist',
              state: { listagem: response.data, model: model },
            });
          }
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
      }
    } else {
      try {
        setLoading(true);
        const response = await api.get(`/checklist-veiculo/consulta-gerenciar-checklist`, {
          params: {
            ...filteredModel,
            tipoFaturamento: formatTypeBilling,
            tipoFrota: formatTypeFleet,
          },
        });

        setLoading(false);
        if (response.status === 200) {
          setDataResults(response.data);
          if (response.data.length === 0) {
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            return;
          } else {
            history.push({
              pathname: '/checklist/resumo-checklist',
              state: { listagem: response.data, model: model },
            });
          }
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
      }
    }
  };

  const verifyIfPlacaIsRegisteredVeiculo = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?idCliente=${model.idClienteEmbarcador}&placaVeiculo=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredVeiculo(true);
        } else {
          setIsPlacaRegisteredVeiculo(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfPlacaIsRegisteredCarreta = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-cliente-carreta?idCliente=${model.idClienteEmbarcador}&placaCarreta=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredCarreta(true);
        } else {
          setIsPlacaRegisteredCarreta(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfNumeroIsRegisteredBau = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/bau-intercambiavel/verificar-bau-cliente-numero-bau?idCliente=${model.idClienteEmbarcador}&numeroBau=${value}`
        );
        if (response.data === true) {
          setIsNumeroRegisteredBau(true);
        } else {
          setIsNumeroRegisteredBau(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const filtraAtivo = (data: any) =>
    data.filter(function (el: any) {
      return el.ativo === true;
    });

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="titulo-container">
              <h2 className="titulo">Relatório Checklist Lançados</h2>
              <h6 className="subtitulo">{'Consultas e Relatórios > Checklist > Lançados'}</h6>
            </div>

            <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
              {isAllowedByRole(['admin']) ? (
                <>
                  <Form.Group>
                    <Form.Label>EMPRESA</Form.Label>
                    <Dropdown
                      style={{ width: '100.4%' }}
                      value={model.idClienteTransportador}
                      options={empresaArr}
                      placeholder="Selecione"
                      onChange={(e: any) => {
                        setModel({
                          ...model,
                          idClienteTransportador: Number(e.target.value),
                        });
                      }}
                      optionLabel="razaoSocial"
                      optionValue="id"
                      showClear
                      filter
                      className="multiselect-custom"
                      id="idClienteTransportador"
                    />
                  </Form.Group>
                </>
              ) : null}

              <Form.Group>
                <Form.Label>Grupo de Usuários</Form.Label>
                <Dropdown
                  style={{ width: '100.4%' }}
                  value={model.idUsuario}
                  options={usuarios}
                  placeholder="Selecione"
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      idUsuario: Number(e.target.value),
                    });
                  }}
                  optionLabel="nome"
                  optionValue="usuCdId"
                  showClear
                  filter
                  className="multiselect-custom"
                  id="idUsuario"
                />
              </Form.Group>
              <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
              <Form.Row>
                <Form.Group className="row-modal">
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                      if (e.target.value !== '') {
                        setModel({
                          ...model,
                          dataInicio: toZoned(e.target.value),
                        });
                      }
                    }}
                    onBlur={() => {
                      if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                        setDataFimError('Data final não pode ser menor que a data inicial.');
                      } else {
                        setDataFimError('');
                      }
                    }}
                    value={dataInicio}
                    type="date"
                    id="date"
                    name="date"
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo Data Início não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="row-modal">
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      e.target.value.length > 10 ? null : setDataFim(e.target.value);
                      if (e.target.value !== '') {
                        setModel({
                          ...model,
                          dataFim: toZoned(e.target.value, false),
                        });
                      }
                    }}
                    onBlur={() => {
                      if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                        setDataFimError('Data final não pode ser menor que a data inicial.');
                      } else {
                        setDataFimError('');
                      }
                    }}
                    value={dataFim}
                    type="date"
                    id="dataFim"
                    name="dataFim"
                    placeholder=""
                  />
                  {dataFimError && (
                    <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                      {dataFimError}
                    </p>
                  )}
                </Form.Group>
              </Form.Row>

              {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}
              <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>

              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  paddingRight: '0',
                  paddingLeft: '0',
                }}
              >
                <Form.Check
                  checked={model.filtrarCarreta}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      filtrarCarreta: e.target.checked,
                    });
                  }}
                  type="checkbox"
                  id="flagEnvioInclusaoSm"
                  label="Incluir checklist de carretas"
                />
              </Form.Group>

              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  paddingRight: '0',
                  paddingLeft: '0',
                }}
              >
                <Form.Label>Tipo de Frota</Form.Label>

                <MultiSelect
                  value={model.tipoFrota}
                  options={TIPO_FROTA_VEICULO}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      tipoFrota: e.value,
                    });
                    setSelectAllTypeBillingVehicle(e.value.length === TIPO_FROTA_VEICULO.length); // Update selectAll based on selection
                  }}
                  emptyFilterMessage={<>Sem resultados</>}
                  maxSelectedLabels={10}
                  placeholder="Selecione os tipo de frota"
                  className="w-full md:w-20rem"
                />
              </Form.Group>

              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  paddingRight: '0',
                  paddingLeft: '0',
                }}
              >
                <Form.Label>Tipo de Faturamento</Form.Label>

                <MultiSelect
                  value={model.tipoFaturamento}
                  options={TIPO_FATURAMENTO_VEICULO}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      tipoFaturamento: e.value,
                    });
                    setSelectAllTypeFleetVehicle(
                      e.value.length === TIPO_FATURAMENTO_VEICULO.length
                    );
                  }}
                  emptyFilterMessage={<>Sem resultados</>}
                  maxSelectedLabels={10}
                  placeholder="Selecione os tipo de frota"
                  className="w-full md:w-20rem"
                />
              </Form.Group>

              <ButtonsForm />
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default FiltroChecklist;
