/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from '../../../config/axiosMaquina';
import { Link, useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import Pagination from '../../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { ChecklistGridType } from '../../../api/model/ChecklistType';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { Button } from 'primereact/button';
import { obterDataHora } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

const ListagemChecklist = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [loading, setLoading] = useState(false);
  const [listaChecklist, setListaChecklist] = useState<ChecklistGridType[]>([]);
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ChecklistGridType[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalPosts, setTotalPosts] = useState(0);
  const { page }: any = useParams();

  const paginate = (pageNumber: number) => {
    history.push(`/cliente/operacional/listar-checklist/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO]}], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await axios.get(`/checklist-veiculo/count-consulta-checklist-veiculo`);
      setTotalPosts(response.data);
    };
    fetchQtdPosts();
  }, []);

  const loadTipo = (tipo: string) => {
    if (tipo === '1') {
      return 'Próprio';
    }
    if (tipo === '2') {
      return 'Agregado';
    }
    if (tipo === '3') {
      return 'Terceiro';
    }
    if (tipo === '') {
      return '';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get('/checklist-veiculo/consulta-checklist-veiculo', {
        params: {
          pagina: page - 1,
          qtdRegistros: itensPerPage,
        },
      });
      setListaChecklist(response.data);

      setSearchResults(response.data);
      setLoading(false);
    };
    fetchData();
  }, [page, itensPerPage]);

  const loadLista = async () => {
    setLoading(true);
    const response = await axios.get('/checklist-veiculo/consulta-checklist-veiculo', {
      params: {
        pagina: page - 1,
        qtdRegistros: itensPerPage,
      },
    });
    setListaChecklist(response.data);
    setSearchResults(response.data);
    setLoading(false);
  };

  useEffect(() => {
    const search = async () => {
      if (searchTerm != '') {
        const response = await axios.get(`/checklist-veiculo/pesquisa-grid`, {
          params: {
            keyword: searchTerm,
            pagina: page - 1,
            qtdRegistros: itensPerPage,
          },
        });
        setSearchResults(response.data);
      }
      if (searchTerm === '') {
        loadLista();
      }
    };
    search();
  }, [searchTerm]);

  function checklistEditar(id: number) {
    history.push(`/cliente/operacional/atualizar-checklist/${id}`);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Checklist Veículos</h2>
          <h6 className="subtitulo">{'Operacional > Solicitações > Checklist Veículos'}</h6>
        </div>

        <div className="search-create mb-4">
          {canInsert ? (
            <Link to="/cliente/operacional/cadastro-checklist" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </Link>
          ) : (
            <Link to="/acessonegado" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </Link>
          )}
          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <i className="pi pi-search"></i>
          </span>
        </div>

        <div className="painel" style={{ width: '100%' }}>
          <div className="table-responsive" style={{ width: '100%' }}>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Transportador</th>
                    <th>Nº Placa / Baú</th>
                    <th>Marca / Modelo</th>
                    <th>Tipo Frota</th>

                    <th>Inclusão</th>
                    <th>Vigência</th>
                    <th></th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((check) => (
                    <tr>
                      <td>{check.nomeCliente}</td>
                      <td>{check.idBauIntercambiavel ? check.numeroBau : check.placaVeiculo}</td>
                      <td>
                        {check.modeloBau
                          ? check.modeloBau
                          : `${check.marcaVeiculo} / ${check.modeloVeiculo}`}
                      </td>
                      <td>
                        {check.idBauIntercambiavel
                          ? loadTipo(check.tipoBau)
                          : loadTipo(check.tipoFrota)}
                      </td>

                      <td>{obterDataHora(check.dataHoraInclusao, true)}</td>
                      <td>
                        <Button
                          tooltip={
                            check.checkList && check.checkList.includes('OK')
                              ? check.checkList
                              : check.listaDispositivo
                              ? `Dispositivos pendentes de manutenção: (${check.listaDispositivo
                                  .filter((fil) => fil.situacaoDispositivo === '3')
                                  .map((each) => each.nomeDispositivo)})`
                              : null
                          }
                          tooltipOptions={{ position: 'bottom' }}
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            padding: 0,
                            paddingRight: 5,
                            marginBottom: 2,
                          }}
                        >
                          {/* TODO: se tiver sat aparece o IoWarning, se tiver vencido: check vermelhor,
                          se nao tiver vencido: check verde */}
                          <IoCheckmarkSharp
                            size={22}
                            color={
                              !check.checkList || check.checkList.includes('OK')
                                ? '#3AE963'
                                : '#E93A3A'
                            }
                          />
                        </Button>
                      </td>
                      <td>
                        <Button
                          tooltip="Visualizar Checklist"
                          tooltipOptions={{ position: 'bottom' }}
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            padding: 0,
                            paddingRight: 5,
                            marginBottom: 2,
                          }}
                        >
                          <i
                            title="Visualizar Checklist"
                            key={check.idCheckListVeiculo}
                            onClick={() => checklistEditar(check.idCheckListVeiculo)}
                            className="pi pi-eye"
                            style={{ color: 'black' }}
                          ></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={searchTerm ? searchResults.length : totalPosts}
              paginate={paginate}
              currentPage={page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListagemChecklist;
