import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import { orderBy } from 'lodash';
import axios from '../../config/axiosMaquina';
import { cpfMask, maskCpnj } from '../../util/Validacoes/Validacoes';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { FiltroVeiculo } from './ConsultarVeiculo';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import getTipoBau from '../../util/Validacoes/getTipoBau';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

type ListagemConsulta = {
  atualizacao: boolean;
  bloqueado: boolean;
  contrato: string;
  idTecnologia: number;
  idVeiculo: number;
  idCliente: number;
  mapa: boolean;
  numeroEquipamento: string;
  placa: string;
  placaVeiculo?: string;
  razaoSocialCliente: string;
  tecnologia: string;
  nomeTecnologia?: string;
  tipoFrota: string;
  proprietario: string;
  cnpjProprietario: string;
  marca: string;
  tipoVeiculo: string;
  renavam: string;
  chassi: string;
  anoFabricacao: string;
  anoModelo: string;
  cidadeEmplacamento: string;
  ufEmplacamento: string;
};

const ListagemConsultaVeiculo = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const [data, setData] = useState<ListagemConsulta[]>([]);
  const [searchResults, setSearchResults] = useState<ListagemConsulta[] | undefined>();
  const [placaVeiculo, setPlacaVeiculo] = useState<string | undefined>();
  const [idVeiculo, setIdVeiculo] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroVeiculo };
  } = useLocation();
  const { state } = location;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [totalSearchResults, setTotalSearchResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [edicaoAcessada, setEdicaoAcessada] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = [
    '+id',
    'Cliente',
    'Placa',
    'Tipo Contrato',
    'Tipo Frota',
    'Tecnologia',
    'N° Equipamento',
    'Bloqueado',
    'Mapa',
    'Atualização Automática',
  ];
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  console.log('current', state.model);

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.VEICULO] }], 'excluir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    setExcelGeneration([
      {
        xSteps: 2,
        ySteps: 0,
        columns: [
          {
            title: 'Veículos',
            widthPx: 1060,
            style: { font: { sz: '24', bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: 'Empresa', width: { wch: 20 } },
          { title: 'Placa', width: { wch: 15 } },
          { title: 'Proprietário', width: { wch: 30 } },
          { title: 'Doc Proprietário', width: { wch: 20 } },
          { title: 'Veículo', width: { wch: 15 } },
          { title: 'Descrição', width: { wch: 30 } },
          { title: 'Tipo Frota', width: { wch: 10 } },
          { title: 'Renavam nº', width: { wch: 15 } },
          { title: 'Chassi nº', width: { wch: 20 } },
          { title: 'Ano', width: { wch: 10 } },
          { title: 'Ano Modelo', width: { wch: 13 } },
          { title: 'Cidade / UF', width: { wch: 15 } },
          { title: 'Tecnologia', width: { wch: 15 } },
          { title: 'Nº Equipamento', width: { wch: 15 } },
          { title: 'Contrato', width: { wch: 15 } },
          { title: 'Atualização Automática', width: { wch: 22 } },
          { title: 'Mapa', width: { wch: 10 } },
        ],
        data: data.map((each) => [
          { value: each.razaoSocialCliente ? each.razaoSocialCliente : '' },
          { value: each.placaVeiculo ? each.placaVeiculo : '' },
          { value: each.proprietario ? each.proprietario : '' },
          {
            value: each.cnpjProprietario
              ? each.cnpjProprietario.length === 11
                ? cpfMask(each.cnpjProprietario)
                : maskCpnj(each.cnpjProprietario)
              : '',
          },
          { value: each.marca ? each.marca : '' },
          { value: each.tipoVeiculo ? each.tipoVeiculo : '' },
          { value: each.tipoFrota ? getTipoBau(each.tipoFrota) : '' },
          { value: each.renavam ? each.renavam : '' },
          { value: each.chassi ? each.chassi : '' },
          { value: each.anoFabricacao === '0' || !each.anoFabricacao ? '' : each.anoFabricacao },
          { value: each.anoModelo === '0' || !each.anoModelo ? '' : each.anoModelo },
          {
            value:
              each.cidadeEmplacamento && each.ufEmplacamento
                ? `${each.cidadeEmplacamento ? each.cidadeEmplacamento : ''} / ${
                    each.ufEmplacamento ? each.ufEmplacamento : ''
                  }`
                : each.cidadeEmplacamento
                ? each.cidadeEmplacamento
                : each.ufEmplacamento
                ? each.ufEmplacamento
                : '',
          },
          { value: each.nomeTecnologia ? each.nomeTecnologia : '' },
          { value: each.numeroEquipamento ? each.numeroEquipamento : '' },
          { value: each.contrato ? each.contrato : '' },
          // { value: each.bloqueado === true ? 'Sim' : 'Não' },
          { value: each.atualizacao === true ? 'Sim' : 'Não' },
          { value: each.mapa === true ? 'Sim' : 'Não' },
        ]),
      },
    ]);
  }, [state, data]);

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await axios.delete(`/veiculo/${id}`);
      console.log(response);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      console.log(err);

      setMensagemErro(err.response.data.message ? err.response.data.message : err.response.data);
    }
  };

  const handleObject = (listagem: any[]) => {
    const array = orderBy(listagem, 'placa').map((each: ListagemConsulta) => {
      return [
        each.idVeiculo,
        each.razaoSocialCliente,
        each.placaVeiculo,
        each.contrato === '' ? 'NÃO INFORMADO' : each.contrato,
        getTipoFrota(each.tipoFrota),
        each.nomeTecnologia,
        each.numeroEquipamento,
        each.bloqueado ? 'Sim' : 'Não',
        each.mapa ? 'Sim' : 'Não',
        each.atualizacao ? 'Sim' : 'Não',
      ];
    });
    return array;
  };

  useEffect(() => {
    setData(orderBy(state?.listagem, 'placa'));
    setDataTabela(handleObject(state?.listagem));
    const veiculo = state?.listagem
      .filter((vei) => vei.idVeiculo === idVeiculo)
      .find((each) => each.placaVeiculo);
    setPlacaVeiculo(veiculo?.placaVeiculo);
  }, [state, idVeiculo]);

  useEffect(() => {
    const reloadDados = async () => {
      if (!searchTerm) {
        setLoading(true);
        try {
          const response = await axios.get(`/veiculo/consulta-gerenciar-veiculo`, {
            params: {
              ...state.model,
            },
          });
          setData(orderBy(response.data, 'placa'));
          setDataTabela(handleObject(response.data));
        } catch (err: any) {
          console.log(err.response);
        } finally {
          setLoading(false);
        }
      }
    };
    reloadDados();
  }, [edicaoAcessada]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(dataTabela, searchTerm));
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Veículo"
      caminho="Cadastro > Gerenciamento de Clientes > Veículo"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchTerm}
      search={search}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Veículo"
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          left
          onClickEditar={(id: any) => {
            setEdicaoAcessada(true);
            history.push({
              pathname: `/atualizarveiculo/${id}`,
              state: state.listagem.find((each: ListagemConsulta) => each.idVeiculo === id)
                ?.idCliente,
            });
          }}
          onClickExcluir={(id: any) => {
            if (canDelete) {
              setExclusaoErro(false);
              setExclusaoOK(false);
              handleShow();
              setIdVeiculo(id);
            } else {
              history.push('/acessonegado');
            }
          }}
        />
      )}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {!exclusaoOK && !exclusaoErro && (
            <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
          )}
          {exclusaoOK && <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>}
        </Modal.Header>

        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro: {`${placaVeiculo}`}?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Registro excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoErro && !exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={handleClose}
              label="Não"
            />
            <Button
              style={{ width: '120px', height: '50px' }}
              variant="secondary"
              onClick={() => handleDelete(idVeiculo)}
              label="Sim"
            />
          </Modal.Footer>
        )}
        {exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={() => {
                window.location.reload();
              }}
              label="OK"
            />
          </Modal.Footer>
        )}
      </Modal>
    </Tela>
  );
};

export default ListagemConsultaVeiculo;
